<template>
    <AdminWrapper>
        <template v-slot:child>
            <!--Balance sheet -->
            <div class="w-90 center">
                <router-link :to="{ name: 'AdminAccountDetails', params: { id: $route.params.id } }" class="pv4-l pv3 mini-spacing"> &lt; Account Details </router-link>

                <div class="flex justify-between items-center pb3 box-border-bottom">
                    <div>
                        <p class="f3 b">Expense Report</p>
                    </div>
                    <div>
                        <!-- <button>Update</button> -->
                    </div>
                </div>
            </div>
            <!--Sales Report sheet -->

            <!-- date, comparison & filter  -->
            <div class="w-90 center pt4">
                <div class="flex justify-between">
                    <div></div>

                    <div class="flex items-center flex-wrap justify-end items-center w-100 pt0-l pt3" style="gap: 1rem">
                        <div class="flex flex-wrap" style="gap: 1rem">
                               <input type="date" name="startDate" class="input-style" v-model="state.startDate" />
                                <input type="date" name="endDate" class="input-style" v-model="state.endDate" />

                            <!-- <template>
                                <input type="date" name="" class="input-style" />
                                <input type="date" name="" class="input-style" />
                            </template> -->
                        </div>

                        <select name="comparison" id="filter" v-model="state.filter">
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Annual">Annually</option>
                        </select>

                        <button class="filter card-box" @click="handleExpenseRecords">
                            <img src="../../../assets/images/filter_list.svg" alt="filter icon" />
                            <span class="pl2 w-50 tl black">Filter</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- date, comparison & filter  -->

            <!-- report of balance sheet -->
            <div class="w-90 center mt4">
                <!-- Business Details -->
                <div class="pa3 box-border">
                    <!-- <OrgData heading="Expense Report " /> -->

                    <!-- statement of profit/lose -->
                    <div class="tc pv4">
                        <div class="b" style="color: #132C8C;">
                            <!-- Daily Sales Report of Bob Adams for the period list Jan 2021-Dec 31st 2021 -->
                            <!-- [Daily] Expense Report of [Company Name] as at [End Date] -->
                             {{ state.filter }} Expense Report of -- as at {{ formatDateInLocal(state.endDate) }}
                        </div>
                    </div>

                    <!-- accounts & date assets, liabilities and co -->
                    <div class="ph2 pt4">
                        <!-- All, Sales, Expenses, Purchase & Other -->
                        <div class="scrollmenu w-90 center">
                            <table class="w-100 tl mt3" cellspacing="0">
                                <thead>
                                    <!--  -->
                                    <tr class="font-w1">
                                        <th class="pb3 pr3 bb b--black-20 tl">Date</th>
                                        <th v-for="date in state.allDates" :key="date" class="pb3 pr3 bb b--black-20 tl">
                                        <th class="">{{ formatDate(date) }}</th>
                                        </th>
                                        <th class="pb3 bb b--black-20 tl pr2">Total</th>
                                    </tr>
                                </thead>
                                <tr  v-for="(result, index) in state.result" :key="index" class="font-w1">
                                    <!-- v-for="(item, index) in state.result[index]" -->
                                    <!-- <td  v-for="(type, index) in state.subTypes" :key="index"  class="pv3 pr3 bb b--black-20 tl">
                                    <td class="pv3 pr3 bb b--black-20 tl flex items-center" >{{ type }}</td>
                                    </td> -->
                                    <td class="pv3 pr3 bb b--black-20 tl">{{expenseSidebarOptions[result.data[0].type]}}</td>
                                    <td  v-for="(iResult, index) in result.data" :key="index"  class="pv3 pr3 bb b--black-20 tl">
                                    <td >{{ formatAmount(iResult.amount, $store.state.Settings?.currency) }}</td>

                                    </td>
                                    <td class="pv3 pr3 bb b--black-20 tl">{{ formatAmount(result.sum, $store.state.Settings?.currency) }}</td>
                                    <!-- <td class="pv3 pr3 bb b--black-20 tl flex items-center">
                                        <img src="imgs/file.svg" class="pr1" alt="" /> Sales
                                    </td>
                                    <td class="pv3 pr3 bb b--black-20 tl">John Wick</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">Black Shoe</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">$1,000</td> -->
                                </tr>
                                  <tr class="font-w1">
                                        <td class="pb3 pr3 bb b--black-20 tl">Total</td>
                                        <td v-for="cal in state.allKeyCalculations.keyCalculation" :key="cal" class="pb3 pr3 bb b--black-20 tl">
                                        <td class="">{{ formatAmount(cal.amount, $store.state.Settings?.currency) }}</td>
                                        </td>
                                        <td class="pb3 bb b--black-20 tl pr2">{{ formatAmount(state.allKeyCalculations.sum, $store.state.Settings?.currency) }}</td>
                                    </tr>
                                <!-- <tr class="font-w1">
                                    <td class="pv3 pr3 bb b--black-20 tl">WU-QQ2</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">2 Nov 2021</td>
                                    <td class="pv3 pr3 bb b--black-20 tl flex items-center">
                                        <img src="imgs/file.svg" class="pr1" alt="" /> Purchase
                                    </td>
                                    <td class="pv3 pr3 bb b--black-20 tl">Davido Joe</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">Gold Watch</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">$1,700</td>
                                </tr>
                                <tr class="font-w1">
                                    <td class="pv3 pr3 bb b--black-20 tl">YU-900</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">2 Oct 2020</td>
                                    <td class="pv3 pr3 bb b--black-20 tl flex items-center">
                                        <img src="imgs/file.svg" class="pr1" alt="" />Expenses
                                    </td>
                                    <td class="pv3 pr3 bb b--black-20 tl">Mark Joel</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">Gold Watch</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">$1,700</td>
                                </tr>
                                <tr class="font-w1">
                                    <td class="pv3 pr3 bb b--black-20 tl">AD-000</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">2 May 2020</td>
                                    <td class="pv3 pr3 bb b--black-20 tl flex items-center">
                                        <img src="imgs/file.svg" class="pr1" alt="" />Others
                                    </td>
                                    <td class="pv3 pr3 bb b--black-20 tl">Love Me</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">Watch</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">$1,700</td>
                                </tr>

                                <tr class="font-w1">
                                    <td class="pv3 pr3 bb b--black-20 tl">QU-112</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">22 Nov 2021</td>
                                    <td class="pv3 pr3 bb b--black-20 tl flex items-center">
                                        <img src="imgs/file.svg" class="pr1" alt="" /> Sales
                                    </td>
                                    <td class="pv3 pr3 bb b--black-20 tl">John Wick</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">Black Shoe</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">$1,000</td>
                                </tr>
                                <tr class="font-w1">
                                    <td class="pv3 pr3 bb b--black-20 tl">YU-900</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">2 Oct 2020</td>
                                    <td class="pv3 pr3 bb b--black-20 tl flex items-center">
                                        <img src="imgs/file.svg" class="pr1" alt="" />Expenses
                                    </td>
                                    <td class="pv3 pr3 bb b--black-20 tl">Mark Joel</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">Gold Watch</td>
                                    <td class="pv3 pr3 bb b--black-20 tl">$1,700</td>
                                </tr> -->
                            </table>
                        </div>

                        <!-- pagination -->
                        <div class="w-90 center pb5">
                            <div class="flex justify-between items-center pa3 font-w1" style="background: #F6F8FE;">
                                <div class="flex items-center ">
                                    <!-- <div class="pr3-l pr2">
                                        Showing
                                    </div> -->
                                    <!-- <div
                                        class="flex items-center pa1 box-border"
                                        style="border: 1px solid rgb(151, 145, 145); background: #ffffff;"
                                    >
                                        25 &nbsp; <img src="imgs/down-o.svg" alt="" />
                                    </div> -->
                                    <!-- <div class="pl3 db-l dn">items per page</div> -->
                                </div>
                                <div class="flex">
                                    <div class="active-page-number">1</div>
                                    <!-- <div class="pl2 pl3-l">2</div>
                                    <div class="pl2 pl3-l active-page-number">Next</div>
                                    <div class="pl2 pl3-l">End</div> -->
                                </div>
                            </div>
                        </div>
                        <!-- pagination -->
                        <!-- save as & export button -->
                        <div class="flex justify-between pv4">
                            <div></div>
                            <div class="w-auto-l w-100 flex">
                                <!-- <button  class="flex items-center ml2">
                                    Export &nbsp; <img src="../../../assets/images/down.svg" alt="" />
                                </button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </AdminWrapper>
</template>

<script>
// import AppWrapper from '@/layout/AppWrapper'
// import OrgData from '@/components/OrgData'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { computed, onMounted, reactive, watch } from 'vue'
import { formatAmount, formatDateInLocal, formatDate, expenseSidebarOptions, formatDateFilter, downloadCSV } from '@/utils/lib'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    name: 'ExpenseReport',
    components: { AdminWrapper },

        setup () {
        const store = useStore()
        const report = computed(() => store.state.Admin.getExpenseReport)
        const route = useRouter()

        const state = reactive({
            startDate: "",
            endDate: "",
            filter: "Daily",
            result: [],
            subTypes: [],
            allDates: [],
            allKeyCalculations: []
        })

        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        const day = new Date().getDate();

        const tableHead = {
            monthly: 'Month',
            daily: 'Day',
            weekly: 'Week',
            annual: 'Year'
        }

        const downloadFile = () => {
            downloadCSV(state.result)
        }

        const handleExpenseRecords = () => {
            if(state.filter.toLowerCase() === "daily" && !state.startDate && !state.endDate) {
                state.startDate = new Date(year, month, 1);
                state.endDate = new Date(year, month, day);

                //Daily: 1st of current Month - Current date
                // console.log("daily")
            } else if(state.filter.toLowerCase() === "weekly" && !state.startDate && !state.endDate) {
                // 1st week of the Year to current week. Week starts from Monday//
                state.startDate = new Date(year, 0, 1);
                state.endDate = new Date(year, month, day);
                // console.log("weekly")
            } else if(state.filter.toLowerCase() === "monthly" && !state.startDate && !state.endDate) {
                // Monthly: Jan of the current year - Current month
                state.startDate = new Date(year, 0, 1);
                state.endDate = new Date(year, month, day);
                // console.log("monthly")
            } else if(state.filter.toLowerCase() === "annual" && !state.startDate && !state.endDate) {
                // 2021 to current Year
                 state.startDate = new Date(2021, 0, 1);
                state.endDate = new Date(year, month, day);
                // console.log("yearly")
            }
            if (state.startDate > state.endDate) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Start date cannot be greater than end date',
                    status: false
                })
            }

            if(!state.filter) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Filter is not selected',
                    status: false
                })
            }

            const query = `startDate=${state.startDate}&endDate=${state.endDate}&filter=${state.filter}`

            store.dispatch('Reporting/getExpenseReport', {id: route.currentRoute.value.params.id, query: query})
        }

        onMounted(() => {
            let date = new Date()
            date.setFullYear(date.getFullYear() - 1)

            const startDate = date;
            const endDate = new Date(year, month, day);
            const filter = "Daily";

            const query = `startDate=${startDate}&endDate=${endDate}&filter=${filter}`

            state.startDate = formatDateFilter(startDate);
            state.endDate = formatDateFilter(endDate);

            store.dispatch('Admin/getExpenseReport', {id: route.currentRoute.value.params.id, query: query})
        })

        watch(
            () => report.value,
            () => {
                // console.log(report.value, "report.value");
                if(report.value && report.value.result && report.value.result.length > 0) {
                    state.result = report.value.result;
                    state.allDates = report.value.allkeys;
                    state.subTypes = report.value.subTypes;
                    state.allKeyCalculations = report.value.allKeyCalculations
                }
            }
         )

        return {
            report,
            formatDateInLocal,
            tableHead,
            state,
            handleExpenseRecords,
            expenseSidebarOptions,
            formatDateFilter,
            downloadCSV,
            downloadFile,
            formatDate,
            formatAmount
        }
    }
}
</script>

<style></style>
